<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">安知课堂</a>
          <i>></i>
          <a href="javascript:;"  class="cur-a">直播落地页</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 5px">
            <div title="课程名称" class="searchboxItem ci-full">
              <span class="itemLabel">直播名称:</span>
              <el-input
                v-model="liveBroadcastName"
                type="text"
                size="small"
                clearable
                placeholder="请输入直播名称"
              />
            </div>
            <div title="是否发布" class="searchboxItem ci-full">
              <span class="itemLabel"> 是否发布: </span>
              <el-select
                v-model="releaseStatus"
                placeholder="请选择"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <el-button
              style="margin-left: 20px"
              class="bgc-bv"
              round
              @click="getData()"
              >查询</el-button
            >
            <div class="btnBox" style="margin-left: 20px">
              <el-button class="bgc-bv" round @click="editAndAdd()"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
              @cell-mouse-enter="M_Enter"
              @cell-mouse-leave="m_leave"
              @sort-change="changeSort"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />

              <el-table-column
                label="直播名称"
                align="center"
                prop="courseName"
                show-overflow-tooltip
                min-width="150px"
              />
              <el-table-column
                label="讲师"
                align="center"
                prop="lecturerName"
                show-overflow-tooltip
                min-width="100px"
              />
              <el-table-column label="直播时间" min-width="150px">
                <template slot-scope="scope">
                  {{ scope.row.courseStartTime }}-{{ scope.row.courseEndTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="发布状态"
                align="center"
                prop="iosPrice"
                show-overflow-tooltip
                min-width="30px"
              >
                <template slot-scope="scope">
                  <span
                    :class="scope.row.courseState == '20' ? 'publishColor' : ''"
                    >{{
                      scope.row.courseState == "10" ? "未发布" : "已发布"
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="创建时间"
                align="center"
                prop="createTime"
                show-overflow-tooltip
                min-width="100px"
              >
              </el-table-column>
              <el-table-column label="操作" align="center" min-width="150px">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="editAndAdd(scope.row.courseId)"
                    >编辑</el-button
                  >
                    <el-button
                      type="text"
                      size="mini"
                      slot="reference"
                      @click="isPublish(scope.row.courseState,scope.row.courseId)"                     
                      >{{
                        scope.row.courseState == "10" ? "发布" : "回收"
                      }}</el-button
                    >
                  <el-button
                    type="text"
                    size="mini"
                    @click.stop="() => cutOut(scope.row.courseId)"
                    >删除</el-button
                  >
                  <el-popover
                    placement="left-start"
                    width="200"
                    trigger="hover"
                    :content="scope.row.miniPageIndex"
                  >
                    <el-button type="text" slot="reference" size="mini">查看链接</el-button>
                  </el-popover>
                  <el-button
                    type="text"
                    size="mini"
                    style="padding: 7px 10px"
                    @click="viewSharingCode(scope.row.courseId)"
                    >扫码预览</el-button
                  >
                  <el-dialog
                    title="二维码"
                    :visible.sync="centerDialogVisible"
                    width="30%"
                  >
                    <el-image
                      style="
                        display: block;
                        margin: 0 auto;
                        width: 400px;
                        height: 400px;
                      "
                      :src="linkurl"
                      :fit="fit"
                    ></el-image>
                    <!-- <span slot="footer" class="dialog-footer">
                      <el-button
                        type="primary"
                        @click="centerDialogVisible = false"
                        >确定</el-button
                      >
                    </span> -->
                  </el-dialog>
                </template>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "liveBroadcastLanding/liveBroadcastLanding",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      liveBroadcastName: "", //直播名称
      releaseStatus: "", //发布状态
      //发布状态选项
      options: [
        {
          value: "10",
          label: "否",
        },
        {
          value: "20",
          label: "是",
        },
      ],
      //   扫码预览弹窗是否打开
      centerDialogVisible: false,
      //   扫码预览图片链接
      linkurl: "",
      dialogFormVisible: false,

      miniCourseTypeList: [], //培训类型
      //  二维码弹框数据
      seeShareData: {
        url: "", // 二维码路径
        title: "", // 弹框标题
      },
      // 二维码弹框 - 状态
      seeShareDialog: false,
    };
  },
  computed: {},
  created() {

  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.liveBroadcastName) {
        params.courseName = this.liveBroadcastName;
      }
      if (this.releaseStatus) {
        params.courseState = this.releaseStatus;
      }
      this.doFetch(
        {
          url: "/course/live/share/pageList",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    //删除
    cutOut(courseId) {
      this.$post(
        "/course/live/share/delete",
        { courseId: courseId },
        3000,
        true,
        2
      )
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
          this.getData(this.pageNum);
        })
        .catch((err) => {
          this.$message({
            type: "info",
            message: "删除失败",
          });
        });
    },
    //新增
    editAndAdd(courseId) {
      this.$router.push({
        path: "/web/miniApp/liveBroadcastLandingPageEditing",
        query: { courseId: courseId },
      });
    },
    //发布和收回
    isPublish(courseState,courseId) {
      const state = courseState == "10" ? "发布" : "回收";
      this.$confirm(`确定${state}吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type: "warning",
      }).then(()=>{
        this.$post(
        "/course/live/share/updateState",
        { courseId: courseId },
        3000,
        true,
        2
      )
        .then((res) => {
          this.getData(-1);
          return;
        })
        .catch(() => {
          return;
        });

      }).catch(() => {
          this.$message({
            type: "info",
            message: `已取消${state}`,
          });
        });
      
    },
    //查看分享码
    viewSharingCode(courseId) {
      this.$post(
        "/course/live/share/qrCode",
        { courseId: courseId },
        3000,
        true,
        2
      )
        .then((res) => {
          this.centerDialogVisible = true;
          this.linkurl = res.message;
          return;
        })
        .catch(() => {
          return;
        });
    },
  },
    beforeRouteLeave: resetKeepAlive,
  
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh || val.query.refresh == "true") {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.pageNum = 1), this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.operationControl > div > div > span {
  min-width: 6rem;
}

.itemLabel {
  display: inline-block;
  width: 3rem;
}
.publishColor {
  color: rgb(157, 205, 103);
}
</style>
<style lang="less" scoped></style>
